<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="title"
        left-arrow
        @click-left="$router.go(-1)"
        @touchmove.prevent
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        require: true
      }
    }
  }
</script>

<style lang="less" scoped>

   /deep/.van-nav-bar__title {
  font-weight: bold;
}
</style>