<template>
      <div class="page">

        
               <Head :title="'使用抵用券'"></Head>


                <div class="main">

                      <div class="box" v-if="list">
                        
                        <div class="item" v-for="(item ,index) in list " :key="item.ids" >

                                <div class="left">

                                      <div class="title" v-if="item.type=='1'">
                                        
                                        {{item.reduceAmount}}<span>元</span>

                                      </div>
                                       <div class="title" v-else align="center">
                                        
                                        {{item.discount}}<span>折</span>

                                      </div>

                                      <div class="rule">

                                        满{{item.premiseAmount}}元可用

                                      </div>
                                </div>


                                <div class="right"> 

                                      <div class="name">

                                        {{item.name}}

                                      </div>

                                      <div class="date">

                                        有效期至{{item.validEndTime.slice(0,10)}}
                                      </div>
                                </div>

                                <div class="icon">
                                  <img src="../../assets/img/checkout.png" v-show="num!==index&&item.premiseAmount<=$route.query.price" alt="" @click="num=index" >
                                  <img src="../../assets/maker/check_in.png" v-show="num===index&&item.premiseAmount<=$route.query.price" alt="" @click="num=null">

                                </div>
                                <img src="../../assets/bukeyong.png"  v-if="item.premiseAmount>$route.query.price"  class="close" alt="">

                        </div>



                      </div>

                </div>

              <div class="footer">
                  <div class="btn" @click="back">
                    确定
                  </div>
              </div>
      </div>
</template>

<script>
import Head from '../../components/commonHead/index.vue'
import { getCouponList } from "../../api/cop/index";

  export default {
    data(){ 

      return{ 

            list:null,

            data:null,

            num:null


      }
    },
  


    created(){ 

        this.setRem()

        getCouponList({spuIds:this.$route.query.spuId,shopId:this.$route.query.shopId}).then(res=>{ 

          if(res.data.code===0){ 

              this.list=res.data.data.records
           

          }

        })
      
    },
    methods:{ 
        back(){ 

          if(this.num){ 
            sessionStorage.setItem('coupon',JSON.stringify(this.list[this.num]))
          }
        this.$router.back()
        }
    },
    components:{Head}
  }
</script>

<style lang="less" scoped>
  .page{ 
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: white;
    font-family: PingFangSC-Semibold, PingFang SC;
    .main{ 
      flex: 1;
      background-color: #f6f6f6;
      overflow-y: auto;
      box-sizing: border-box;
      .box{ 

        padding:10px 16px  50px;
        .item{ 
          position: relative;
              width: 100%;
              border-radius: .48rem;
              background-color: white;
              display: flex;
              padding: .266667rem .32rem;
              color: #999;
              font-size: 14px;
              box-sizing: border-box;
              margin-bottom: .266667rem;
                    .left{ 
                      height: 100%;
                      border-right: 1px dotted  #E0E0E0;
                      padding-top: .533333rem;
                      padding-right: .266667rem;
                            .title{ 
                            color: #333;
                            font-size:30px ;
                            font-weight: 600;
                            }
                            span{ 
                              font-weight: 400;
                              font-size: 14px;
                            }
                            .rule{ 
                              font-size: 12px;
                              margin-bottom: .426667rem;
                            }
                       }

                      .right{ 

                        padding-top: .4rem;
                        padding-left: 0.5rem;

                            .name{ 
                              margin-bottom: 20px;
                            }
                            flex: 1;
                      }
                      .icon{ 
                            img{ 
                                width: .666667rem;
                                height: .666667rem;
                            }

                          display: flex;
                          padding: 0 .533333rem;
                          box-sizing: border-box;
                          align-items: center;

                      }
                      .close{ 
                        position: absolute;
                        height:1.52rem ;
                        width: 1.52rem;
                        right: 0;
                        top: 0;
                      }
                      

        }
      }
    }
    .footer{ 
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      background-color: white;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      .btn{ 
        width:344px ;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
        background-image: linear-gradient(to right,#FF7200,#FF3C00);
        margin: 10px auto;
        color: white;
      }
    }
  }
</style>